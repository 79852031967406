<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          :loading="loading"
          hide-default-footer
          :page.sync="page"
          :items-per-page="table.itemsPerPage"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Pengajuan Arisan</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="700px">
                <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Tambah </v-btn>
              </template> -->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-simple-table dense style="width: 100%">
                          <tbody>
                            <tr>
                              <td><b>Kode Anggota</b> : {{ editedItem.kode_anggota }}</td>
                              <td><b>Nama</b> : {{ editedItem.nama_anggota }}</td>
                              <td>
                                <b>Wilayah</b> :
                                <span v-if="editedItem.wilayah_luar === '1'" style="color: red">{{
                                  editedItem.wilayah
                                }}</span>
                                <span v-else>{{ editedItem.wilayah }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Tgl Lahir</b> : {{ formatDate(editedItem.tgl_lahir) }}</td>
                              <td><b>Divisi</b> : {{ editedItem.divisi }}</td>
                              <td><b>Jenis Upah</b> : {{ editedItem.jenis_upah.value }}</td>
                            </tr>
                            <tr>
                              <td><b>TGL Masuk</b> : {{ formatDate(editedItem.tgl_masuk) }}</td>
                              <td><b>Jabatan</b> : {{ editedItem.jabatan }}</td>
                              <td>
                                <b>Jumlah Upah : </b>{{ new Intl.NumberFormat(['id']).format(editedItem.jumlah_upah) }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        <v-col cols="12" v-if="editedItem.status == 'Tunggu'">
                          <v-form class="multi-col-validation">
                            <v-row>
                              <v-col cols="12">
                                <v-autocomplete
                                  label="Grup Arisan *"
                                  v-model="editedItem.id_grup"
                                  :items="grups"
                                  dense
                                  item-text="text"
                                  item-value="value"
                                  outlined
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-col>
                        <v-simple-table v-else dense style="width: 100%; margin-top: 20px">
                          <tbody>
                            <tr>
                              <td><b>TGL ACC</b> : {{ formatDate(editedItem.updated_at) }}</td>
                              <td><b>ID Grup</b> : {{ editedItem.id_grup }}</td>
                              <td><b>Nama Grup</b> : {{ editedItem.nama }}</td>
                            </tr>
                            <tr>
                              <td colspan="3"><b>Status</b> : {{ editedItem.status }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-if="editedItem.status == 'Tunggu'" @click="save()"> Terima </v-btn>
                    <v-btn color="error" v-if="editedItem.status == 'Tunggu'" @click="tolak()"> Tolak </v-btn>
                    <v-btn color="secondary" @click="close()"> Batal </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-form class="ml-4">
              <v-row>
                <v-col cols="1" md="1">
                  <label for="firstname">Status</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="status"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table.status"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Jenis Upah</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="jenis_upahs"
                    outlined
                    dense
                    v-model="table.jenis_upah"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>

          <template v-slot:item.anggota="{ item }">
            {{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}
          </template>
          <template v-slot:item.tgl_pengajuan="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <!-- <template v-slot:item.status="{ item }">
            <span v-if="item.id_petugas_acc == null">PENDING</span>
            <span v-else>DITERIMA</span>
          </template> -->
          <template v-slot:item.aksi="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" title="Lihat"> {{ icons.mdiEye }} </v-icon>
            <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="getDataFromApi()"> Reload </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiEye } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
const apiRootFolder = process.env.VUE_APP_APIROOT
export default {
  setup() {
    return {
      icons: {
        mdiEye,
      },
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Anggota', value: 'anggota', sortable: false },
      { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
      { text: 'Tanggal Pengajuan', value: 'tgl_pengajuan', sortable: false },
      { text: 'Arisan', value: 'nama', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    desserts: [],
    loading: false,
    page: 1,
    table: {
      pageCount: 0,
      itemsPerPage: 25,
      // search: '',
      status: 'SEMUA',
      jenis_upah: 'SEMUA',
    },
    length_menus: [25, 50, 100, 500, 1000],
    jenis_upahs: ['HARIAN', 'MINGGUAN', 'BULANAN', 'SEMUA'],
    status: ['Tunggu', 'Terima', 'Tolak', 'SEMUA'],
    grups: [],
    editedIndex: -1,
    editedItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: 2,
      jenis_upah: { text: 'HARIAN', value: 'HARIAN' },
      aktif: true,
    },
    defaultItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: '',
      jenis_upah: '',
      aktif: '',
    },
    jenis_upah: [
      { text: 'HARIAN', value: 'HARIAN' },
      { text: 'MINGGUAN', value: 'MINGGUAN' },
      { text: 'BULANAN', value: 'BULANAN' },
    ],
    search: '',
    apiRootFolder: apiRootFolder,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Program Pinjaman' : 'Detail Pengajuan'
    },
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  created() {
    this.getDataFromApi()
  },

  methods: {
    optionChange() {
      this.page = 1
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      // form.append('search', this.search)
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('status', this.table.status)
      form.append('jenis_upah', this.table.jenis_upah)
      axios
        .post(`${apiRoot}/api/Arisan/getpengajuanArisanDatatable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    editItem(item) {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('jenis_upah', item.jenis_upah)
      axios
        .post(`${apiRoot}/api/Arisan/getGrupByJenisUpah`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            response.data.data.forEach(element => {
              this.grups.push({
                text: `${element.nama} (${element.total_anggota})`,
                value: element.id,
              })
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.jenis_upah = { text: item.jenis_upah, value: item.jenis_upah }
      this.dialog = true
    },

    close() {
      this.dialog = false
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },
    save() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_pengajuan', this.editedItem.id_pengajuan)
      form.append('id_anggota', this.editedItem.id_anggota)
      form.append('id_grup', this.editedItem.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/savePengajuanAnggotaGrupArisan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
            this.close()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    tolak() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_pengajuan', this.editedItem.id_pengajuan)
      axios
        .post(`${apiRoot}/api/Arisan/tolakPengajuanAnggotaGrupArisan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
            this.close()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
